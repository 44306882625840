import React, { Component } from 'react';  
import { Row } from 'react-flexbox-grid';


export default class NotFound extends Component {
  render() {
    return (
      <div className="Main-Container">
        <Row className="center-md">
          <div id="Main-Container"> 
            <h2 id="Large-Title">404 Not Found</h2>
            <p id="Large-Subtitle">page not found</p>
          </div>
        </Row>
      </div>
    );
  }
}
