import React, { Component } from 'react';
import Profile from './Profile'
import Nav from './components/Nav'
import {Helmet} from "react-helmet";
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

export default class App extends Component {

  componentDidMount () {
    if (window.location.pathname === '/') {
      window.location = 'https://oblip.com'
    }
  }

  render() {
    return (
      <Router basename={process.env.PUBLIC_URL  + '/'}>
         <Helmet>
            <title>Oblip.me</title>
            <meta name="description" content="Share your Oblip account to get paid" />
            {/* OpenGraph tags */}
            <meta name="og:url" content="https://oblip.me" />
            <meta name="og:title" content="Oblip.me" />
            <meta name="og:description" content="A new innovative way to keep your money secure" />
            <meta name="og:image" content="/logo.png" />
            <meta name="og:type" content="website" />
            <meta name="fb:app_id" content={`facebook.${process.env.FACEBOOK_APP_ID}`} />
            {/* Twitter Card tags */}
            {/* <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary" />
            <meta
              name="twitter:creator"
              content={config.authorTwitterAccount ? config.authorTwitterAccount : ""}
            /> */}
        </Helmet>
        <Nav/>
          <Route exact path="/:username" component={Profile} />
      </Router>
    );
  }
}
