import React, { Component } from 'react';  
import { Row, Col } from 'react-flexbox-grid';


export default class Nav extends Component {
  render() {
    return (
      <nav> 
       <Row center="xs">
           <Col md={12}>
            <div id="Brand-Container"> 
              <img src='/logo.png' id="Brand-Logo" alt="oblip.me" />
                {/* <h3 className="Brand-Name">Oblip</h3> */}
            </div>
           </Col>
           <Col>
              {/* Links if needed */}
           </Col>
       </Row>
      </nav>
    );
  }
}
